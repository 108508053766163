import { NextMenuItem } from '@wwtfTypes/menu.type';
import { NextIcon } from '@components/atoms/visual/enums/icon.enum';
import {
  AlignRow,
  ColumnSize,
  FieldType,
  TableField,
  TemplateType
} from '@components/organisms/next-table/next-table.type';
import {
  GenericTableColumnProps,
  NextFilterType,
  TableFieldProps
} from '@components/organisms/next-table/enums/next-table.enum';
import {
  VehicleOrderProps,
  VehicleOrderSection
} from '@pages/new-business/pages/vehicle-order/enums/vehicle-order.enum';
import { NextFormatCurrencyPipe } from '@pipes/next-format-currency.pipe';
import { GenericObject } from '@utils/models/Types';
import { FormSection } from '@components/organisms/layout/types/form-section.type';
import { FormSectionProps } from '@components/organisms/layout/enum/form-section.enum';
import { NextRouteProps } from 'app/core/enums/route.enum';
import { VehicleOrderRouteConfig } from '../vehicle-order.route.config';
import { VehicleOrderAction } from '../pages/vehicle-order-manager/enums/vehicle-order-manager.enum';
import { DataTitleConfig } from '@components/atoms/visual/components/data-title/data-title.config';
import { DataTitleProps } from '@components/atoms/visual/components/data-title/data-title.enum';

export class VehicleOrderConstant {
  static actions: NextMenuItem[] = [
    {
      labelKey: 'VEHICLE_ORDER.ACTIONS.ADD_VEHICLE_ORDER',
      nextIcon: NextIcon.PLUS,
      routerLink: [VehicleOrderRouteConfig.newVehicleOrderConfig[NextRouteProps.PATH]]
    },
    {
      labelKey: 'VEHICLE_ORDER.ACTIONS.UPLOAD_VEHICLE_ORDER_FILE',
      nextIcon: NextIcon.PLUS,
      routerLink: undefined
    }
  ];

  static tableListConfig: TableField[] = [
    {
      [TableFieldProps.FIELD]: VehicleOrderProps.ORDER_DATE,
      [TableFieldProps.HEADER]: 'VEHICLE_ORDER.FIELDS.ORDER_DATE',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true,
      [TableFieldProps.FIELD_TYPE]: [FieldType.DATE],
      [TableFieldProps.FILTER_TYPE]: NextFilterType.DATE
    },
    {
      [TableFieldProps.FIELD]: VehicleOrderProps.ORDER_ID,
      [TableFieldProps.HEADER]: 'VEHICLE_ORDER.FIELDS.ORDER_NUMBER',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: [VehicleOrderProps.DISTRIBUTOR_ID, VehicleOrderProps.DISTRIBUTOR_NAME],
      [TableFieldProps.SEPARATOR]: ' - ',
      [TableFieldProps.HEADER]: 'VEHICLE_ORDER.FIELDS.DISTRIBUTOR_CODE_NAME',
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: VehicleOrderProps.PRODUCT_LINE,
      [TableFieldProps.HEADER]: 'VEHICLE_ORDER.FIELDS.PRODUCT_LINE',
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: VehicleOrderProps.VEHICLE_MODEL,
      [TableFieldProps.HEADER]: 'VEHICLE_ORDER.FIELDS.VEHICLE_MODEL',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: VehicleOrderProps.ORDER_AMOUNT,
      [TableFieldProps.HEADER]: 'VEHICLE_ORDER.FIELDS.ORDER_VALUE',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.PIPE_LIST]: [
        {
          pipe: NextFormatCurrencyPipe,
          args: [{ fromValue: VehicleOrderProps.ORDER_CURRENCY }]
        }
      ]
    },
    {
      [TableFieldProps.FIELD]: VehicleOrderProps.ORDER_STATUS,
      [TableFieldProps.HEADER]: 'VEHICLE_ORDER.FIELDS.ORDER_STATUS',
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: GenericTableColumnProps.VIEW,
      [TableFieldProps.HEADER]: 'TABLE.FIELDS.VIEW',
      [TableFieldProps.ACTION]: {},
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.ACTION,
      [TableFieldProps.ALIGN]: AlignRow.CENTER,
      [TableFieldProps.WIDTH]: ColumnSize.VERY_SMALL
    },
    {
      [TableFieldProps.FIELD]: GenericTableColumnProps.MENU_LIST,
      [TableFieldProps.HEADER]: 'TABLE.FIELDS.OPTIONS',
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.MENU_ACTION,
      [TableFieldProps.ALIGN]: AlignRow.CENTER,
      [TableFieldProps.WIDTH]: ColumnSize.VERY_SMALL
    },
    {
      [TableFieldProps.FIELD]: VehicleOrderProps.ORDER_CURRENCY,
      [TableFieldProps.IS_FILTER]: true,
      [TableFieldProps.IS_HIDDEN]: true
    }
  ];

  static globalFilter = [VehicleOrderProps.ORDER_ID];

  static formSectionList: any[] = [VehicleOrderSection.GENERAL_INFORMATION];

  static formPath = {
    [VehicleOrderSection.GENERAL_INFORMATION]: 'general-information'
  };

  static formSectionConfig: GenericObject<FormSection> = {
    [VehicleOrderSection.GENERAL_INFORMATION]: {
      [FormSectionProps.ID]: VehicleOrderSection.GENERAL_INFORMATION,
      [FormSectionProps.LABEL_KEY]: 'DISTRIBUTOR.MANAGER.FORM.GENERAL_INFORMATION.TITLE',
      [FormSectionProps.PATH]: VehicleOrderConstant.formPath[VehicleOrderSection.GENERAL_INFORMATION]
    }
  };

  static formMaxLength = {
    [VehicleOrderProps.DISTRIBUTOR_NAME]: 75,
    [VehicleOrderProps.ORDER_NUMBER]: 20
  };

  static modifyOrderTableListConfig: TableField[] = [
    {
      [TableFieldProps.FIELD]: VehicleOrderProps.ORDER_NUMBER,
      [TableFieldProps.HEADER]: 'VEHICLE_ORDER.BULK_MODIFY.VEHICLE_ORDERS'
    }
  ];

  static actionConfig = {
    [VehicleOrderAction.MODIFY]: {
      label: 'ACTIONS.MODIFY',
      icon: NextIcon.MODIFY
    },
    [VehicleOrderAction.CANCEL]: {
      label: 'ACTIONS.CANCEL',
      icon: NextIcon.CROSS
    }
  };

  static generalDetailsMap: DataTitleConfig[] = [
    {
      [DataTitleProps.PATH]: [VehicleOrderProps.ORDER_DATE],
      [DataTitleProps.TITLE_KEY]: 'VEHICLE_ORDER.FIELDS.ORDER_DATE'
    },
    {
      [DataTitleProps.PATH]: [VehicleOrderProps.DISTRIBUTOR_ID],
      [DataTitleProps.TITLE_KEY]: 'VEHICLE_ORDER.FIELDS.DISTRIBUTOR_CODE'
    },
    {
      [DataTitleProps.PATH]: [VehicleOrderProps.DISTRIBUTOR_NAME],
      [DataTitleProps.TITLE_KEY]: 'VEHICLE_ORDER.FIELDS.DISTRIBUTOR_NAME'
    },
    {
      [DataTitleProps.PATH]: [VehicleOrderProps.PRODUCT_LINE],
      [DataTitleProps.TITLE_KEY]: 'VEHICLE_ORDER.FIELDS.PRODUCT_LINE'
    },
    {
      [DataTitleProps.PATH]: [VehicleOrderProps.SOURCE_LOCATION],
      [DataTitleProps.TITLE_KEY]: 'VEHICLE_ORDER.FIELDS.SOURCE_LOCATION'
    },
    {
      [DataTitleProps.PATH]: [VehicleOrderProps.VEHICLE_MODEL],
      [DataTitleProps.TITLE_KEY]: 'VEHICLE_ORDER.FIELDS.VEHICLE_TYPE'
    },
    {
      [DataTitleProps.PATH]: [VehicleOrderProps.ORDER_AMOUNT],
      [DataTitleProps.TITLE_KEY]: 'VEHICLE_ORDER.FIELDS.ORDER_VALUE'
    }
  ];

  static generalInformationConfigMap = [
    {
      [DataTitleProps.PATH]: [VehicleOrderProps.DISTRIBUTOR_GROUP],
      [DataTitleProps.TITLE_KEY]: 'VEHICLE_ORDER.FIELDS.DISTRIBUTOR_GROUP'
    },
    {
      [DataTitleProps.PATH]: [VehicleOrderProps.VEHICLE_MODEL],
      [DataTitleProps.TITLE_KEY]: 'VEHICLE_ORDER.FIELDS.VEHICLE_MODEL'
    },
    {
      [DataTitleProps.PATH]: [VehicleOrderProps.PROCESSED_DATE],
      [DataTitleProps.TITLE_KEY]: 'VEHICLE_ORDER.FIELDS.PROCESSED_DATE'
    },
    {
      [DataTitleProps.PATH]: [VehicleOrderProps.COUNTRY_ACCOUNT_MANAGER],
      [DataTitleProps.TITLE_KEY]: 'VEHICLE_ORDER.FIELDS.COUNTRY_ACCOUNT_MANAGER'
    }
  ];
}
