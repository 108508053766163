import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { FormSectionProps } from '@components/organisms/layout/enum/form-section.enum';
import { StepsFormAbstractComponent } from '@components/organisms/steps-form-module/steps-form-abstract/steps-form-abstract.component';
import { VehicleOrderSection } from '../../../../enums/vehicle-order.enum';
import { VehicleOrderConstant } from '../../../../constants/vehicle-order.constant';
import { VehicleOrderRouteConfig } from '../../../../vehicle-order.route.config';
import { VehicleOrderAdminService } from '../../../../services/vehicle-order-admin.service';
import { VehicleOrder } from '../../../../types/vehicle-order.type';
import { TaskBoardService } from '@pages/task-board/services/task-board.service';
import { TaskBoardRouteConfig } from '@pages/task-board/task-board.route.config';
import { VehicleOrderService } from '@pages/new-business/pages/vehicle-order/services/vehicle-order.service';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vehicle-order-form',
  templateUrl: './vehicle-order-form.component.html',
  styleUrl: './vehicle-order-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleOrderFormComponent extends StepsFormAbstractComponent<VehicleOrderSection> implements OnInit {
  protected readonly FormSectionProps = FormSectionProps;
  protected readonly VehicleOrderSection = VehicleOrderSection;

  constructor(
    private $injector: Injector,
    private _vehicleOrderAdminService: VehicleOrderAdminService,
    private _taskBoardService: TaskBoardService,
    private _vehicleOrderService: VehicleOrderService,
    private _translateService: TranslateService
  ) {
    super($injector);
  }

  override ngOnInit() {
    super.ngOnInit();
    this._setEntityConfig();
    this.handleEditMode();
  }

  handleEditMode() {
    if (this.isEdition) {
      if (this.isTaskBoard) {
        this.groupFormRouteConfig = TaskBoardRouteConfig.vehicleOrderEdit;
        this._taskBoardService.getVehicleOrder(this.id).subscribe((response: VehicleOrder) => {
          try {
            this._initializeForm(response);
          } catch (e) {
            this._toastService.error('Task Board is Corrupted');
          }
        });
      } else {
        this._vehicleOrderService.getVehicleOrder(this.id).subscribe((response: VehicleOrder) => {
          this._initializeForm(response);
        });
      }
    } else {
      this._defineFormGroup();
      super.initialize();
    }
  }

  handleSaveButton() {
    this.handleSave();
  }

  // TODO Should be done in abstract
  handleSave(goToNextSection = false) {
    console.log('save');
  }

  handleSubmitButton() {
    const formValues = NextObjectHelper.getPropertyFromObject(this.formGroup.getRawValue(), [
      VehicleOrderSection.GENERAL_INFORMATION
    ]);
    const order = this._vehicleOrderAdminService.formatCreateOrder(formValues);
    this._vehicleOrderService.createOrder(order).subscribe((resp: any) => {
      this._toastService.success('API.SAVE_SUCCESS', {
        entity: this._translateService.instant('VEHICLE_ORDER.ENTITY'),
        id: NextObjectHelper.getPropertyFromObject(resp, ['result', 'orderCreationResponse', 'id'])
      });
      this.formGroup.markAsPristine();
      this._navigationService.navigateToRouteConfig(VehicleOrderRouteConfig.mainRoute).then();
    });
  }

  private _setEntityConfig() {
    this.formSectionList = VehicleOrderConstant.formSectionList;
    this.formSectionConfig = VehicleOrderConstant.formSectionConfig;
    this.redirectRouteConfig = VehicleOrderRouteConfig.mainRoute;
    this.groupFormRouteConfig = VehicleOrderRouteConfig.vehicleOrderFormStep;
  }

  private _defineFormGroup(vehicleOrder?: VehicleOrder) {
    this.formGroup = this._vehicleOrderAdminService.buildVehicleOrderFormGroup(vehicleOrder);
  }

  private _initializeForm(vehicleOrder: VehicleOrder) {
    this._defineFormGroup(vehicleOrder);
    super.initialize();
    this._subscribeFormChanges();
  }
}
