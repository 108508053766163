import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DropdownList } from '@components/atoms/form-inputs/components/next-dropdown/types/dropdown.type';
import { InputNumberType } from '@components/atoms/form-inputs/enums/input.enum';
import { SourceLocationService } from '@pages/distributor/services/source-location.service';
import { VehicleOrderConstant } from '@pages/new-business/pages/vehicle-order/constants/vehicle-order.constant';
import { VehicleOrderProps } from '@pages/new-business/pages/vehicle-order/enums/vehicle-order.enum';
import { VehicleOrderService } from '@pages/new-business/pages/vehicle-order/services/vehicle-order.service';
import { merge, Observable } from 'rxjs';
import { DistributorService } from '@pages/distributor/services/distributor.service';
import { CreditProps } from '@pages/distributor/enums/credit.enum';
import { SCLProps } from '@pages/distributor/constant/securities-credit-limits.enum';
import { GenericBaseComponent } from '@components/atoms/abstract-base/components/generic-base/generic-base.component';
import { NextDateHelper } from '@utils/core/next-date.helper';

@Component({
  selector: 'vehicle-order-general-information',
  templateUrl: './vehicle-order-general-information.component.html',
  styleUrl: './vehicle-order-general-information.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleOrderGeneralInformationComponent extends GenericBaseComponent implements OnInit {
  @Input() formGroup: FormGroup;

  productLineList: DropdownList;
  sourceLocation$: Observable<DropdownList>;
  vehicleType$: Observable<DropdownList>;
  distributorList$: Observable<DropdownList>;

  protected readonly VehicleOrderProps = VehicleOrderProps;
  protected readonly InputNumberType = InputNumberType;
  protected readonly VehicleOrderConstant = VehicleOrderConstant;
  protected readonly CreditProps = CreditProps;
  protected readonly SCLProps = SCLProps;

  constructor(
    private _vehicleOrderService: VehicleOrderService,
    private _sourceLocationService: SourceLocationService,
    private _distributorService: DistributorService
  ) {
    super();
  }

  ngOnInit(): void {
    this._initializeFormConfig();
    this._formChangeEvents();
  }

  private _initializeFormConfig() {
    this.sourceLocation$ = this._sourceLocationService.getDropdownList();
    this.vehicleType$ = this._vehicleOrderService.getVehicleTypeDropdownList();
    this.distributorList$ = this._distributorService.getDropdownList();
  }

  private _formChangeEvents() {
    const distributorIdControl = this.formGroup.get(VehicleOrderProps.DISTRIBUTOR_ID);
    const sourceLocationIdControl = this.formGroup.get(VehicleOrderProps.SOURCE_LOCATION);
    const distributorChangesSub = merge(distributorIdControl!.valueChanges,sourceLocationIdControl!.valueChanges).subscribe(() => {
      if (distributorIdControl?.value && sourceLocationIdControl?.value) {
        this._distributorService
          .getProductLineByDistributorAndSourceLocation(distributorIdControl.value, sourceLocationIdControl.value)
          .subscribe(productLineList => {
            this.productLineList = productLineList;
          });
      } else if (distributorIdControl?.value) {
        this._distributorService
          .getProductLineByDistributorId(distributorIdControl.value)
          .subscribe(productLineList => {
            this.productLineList = productLineList;
          });
      } else {
        this.productLineList = [];
      }
    });
    this.subs.push(distributorChangesSub);
  }
}
