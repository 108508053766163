@defer (when ready) {
<steps-form-generic
    [titleKey]="
    computedFormSectionConfig | safeGuardObjectProperty: [this.activeSectionSig(), FormSectionProps.LABEL_KEY]
    "
    [formSectionList]="formSectionList"
    [activeSection]="activeSectionSig()"
    [formSectionConfig]="computedFormSectionConfig"
    [isDisabledBackButton]="false"
    [showSaveButton]="false"
    [isDisabledFinishButton]="formGroup.invalid"
    (cancelClick)="handleCancelButton()"
    (saveClick)="handleSaveButton()"
    (submitClick)="handleSubmitButton()"
>
    <ng-container [ngSwitch]="activeSectionSig()">
        <vehicle-order-general-information
        *ngSwitchCase="VehicleOrderSection.GENERAL_INFORMATION"
        [formGroup]="formGroup | deepControl: [VehicleOrderSection.GENERAL_INFORMATION]"></vehicle-order-general-information>
    </ng-container>
</steps-form-generic>
} @placeholder {
    ...loading
}