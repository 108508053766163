<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [VehicleOrderProps.ORDER_DATE] as orderDateControl"
    [control]="orderDateControl"
    [labelKey]="'VEHICLE_ORDER.ADD_NEW.FIELDS.ORDER_DATE'"
    slot1
  >
    <next-calendar
      [control]="orderDateControl"
      [testIdDataAttr]="VehicleOrderProps.ORDER_DATE"
      inputElement
    ></next-calendar>
  </input-container>
</column-pair>

<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [VehicleOrderProps.ORDER_NUMBER] as orderNumberControl"
    [control]="orderNumberControl"
    [labelKey]="'VEHICLE_ORDER.ADD_NEW.FIELDS.ORDER_NUMBER'"
    slot1
  >
    <next-input
      [control]="orderNumberControl"
      [testIdDataAttr]="VehicleOrderProps.ORDER_NUMBER"
      inputElement
    ></next-input>
  </input-container>
</column-pair>
<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [VehicleOrderProps.DISTRIBUTOR_ID] as distributorControl"
    [control]="distributorControl"
    [labelKey]="'VEHICLE_ORDER.ADD_NEW.FIELDS.DISTRIBUTOR_NAME'"
    slot1
  >
    <next-dropdown
      [control]="distributorControl"
      [itemsObs$]="distributorList$"
      [testIdDataAttr]="VehicleOrderProps.DISTRIBUTOR_ID"
      inputElement
    ></next-dropdown>
  </input-container>
  <input-container
    *ngIf="formGroup | deepControl: [VehicleOrderProps.SOURCE_LOCATION] as sourceLocationControl"
    [control]="sourceLocationControl"
    [labelKey]="'VEHICLE_ORDER.ADD_NEW.FIELDS.SOURCE_LOCATION'"
    slot2
  >
    <next-dropdown
      [control]="sourceLocationControl"
      [itemsObs$]="sourceLocation$"
      [testIdDataAttr]="VehicleOrderProps.SOURCE_LOCATION"
      inputElement
    ></next-dropdown>
  </input-container>
</column-pair>
<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [VehicleOrderProps.PRODUCT_LINE] as productLineControl"
    [control]="productLineControl"
    [labelKey]="'VEHICLE_ORDER.ADD_NEW.FIELDS.PRODUCT_LINE'"
    slot1
  >
    <next-dropdown
      [control]="productLineControl"
      [items]="productLineList"
      [testIdDataAttr]="VehicleOrderProps.PRODUCT_LINE"
      inputElement
    ></next-dropdown>
  </input-container>
</column-pair>
<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [VehicleOrderProps.VEHICLE_MODEL] as vehicleTypeControl"
    [control]="vehicleTypeControl"
    [labelKey]="'VEHICLE_ORDER.ADD_NEW.FIELDS.VEHICLE_TYPE'"
    slot1
  >
    <next-input
      [control]="vehicleTypeControl"
      [testIdDataAttr]="VehicleOrderProps.VEHICLE_MODEL"
      inputElement
    ></next-input>
  </input-container>
</column-pair>
<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [VehicleOrderProps.ORDER_AMOUNT] as orderValueControl"
    [control]="orderValueControl"
    [labelKey]="'VEHICLE_ORDER.ADD_NEW.FIELDS.ORDER_VALUE'"
    slot1
  >
    <next-input-number [control]="orderValueControl" [type]="InputNumberType.AMOUNT" inputElement></next-input-number>
  </input-container>
</column-pair>
